import React from "react";

const Contact = () => {
  return (
    <div>
      <h1>Sledss demo</h1>
      <p>Welome to demo page</p>
    </div>
  );
};

export default Contact;
